import { Confirmation_, Cta_, Focus_, Grey_, Grey_Link, IconSvg, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageForty, PageTitle } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';

import styled from 'styled-components'
import { NifflerInfoRow, NifflerSvgRow, NifflerWrapper } from '../../styles/Layout'
import querystring, { parse } from 'query-string'
import {get_form, get_coupon, get_coupon_user} from '../../services/api';
import store from '../../redux/store'

import LandSvg from '../../assets/beforeContact.svg'


const StartDetails = styled.div`
    margin-top: 2rem;
`

const StartActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
`

const TempSvg = styled.div`
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img{
        max-width: 80rem;
    }
`

export default function START(props) {

    console.log(window?.location)

    const parsedurl = querystring.parse(window?.location?.search)

    let navigate = useNavigate();
    let location = useLocation();
    let url = location.pathname.split('/');
    let formId = url[url.length-1]

    const { keycloak } = useKeycloak();


    const [ detailsConfirmation, toggleDetailsConfirmation] = useState(false)
    const [ risk, setRisk] = useState(false)
    const [ valid, setValid] = useState(false)
    const [ user, setUser] = useState({sub: 'NOT LOADED', token: 'NOT LOADED'})

    const check_validity_of_coupon_and_user = (data) => {
        if (data?.couponExists == false) navigate(`/${parsedurl.lang}/late/`)
        else if (data?.couponExpired == true) navigate(`/${parsedurl.lang}/late/`)
    }

    const get_valid_coupon = () => {
        get_coupon(formId, user.sub, user.token).then((valid) => {
            check_validity_of_coupon_and_user(valid?.data)
            setValid(valid?.data) 
        })
    }

    const i_want_this_job = () => {

        if (user.sub == 'NO AUTH') {
            navigate(`/el/join?formId=${formId}`)
        }
        else {
            if (user.sub) navigate('/el/contact/form?formId=' + formId)
            else navigate(`/el/contact/form?formId=${formId}`)
        }
    }
    
    const acceptRisk = () => {
        toggleDetailsConfirmation(!detailsConfirmation)
        setRisk(true)
    }

    const rejectRisk = () => {
        toggleDetailsConfirmation(!detailsConfirmation)
        setRisk(false)
    }

    useEffect(() => {
        if (parsedurl.contact == 'true') {

            navigate('/el/contact/form?formId='
                                        +formId)
        }
        if (!valid && user.sub !== 'NOT LOADED' && user.token !== 'NOT LOADED') get_valid_coupon();
    },[user])

    useEffect(() => {
        // if user is not authenticated do nothing, else, load user info
            if (keycloak.authenticated) {
                keycloak.loadUserInfo()
                    .then(data=> {
                        setUser({sub: data.sub, token: keycloak.token})
                        store.dispatch({type:'KEYCLOAK', payload: {sub: data.sub, token: keycloak.token}})
                    })
                    .catch(() => {
                        setUser({sub:'NO AUTH', token:'NO AUTH'})
                        store.dispatch({type:'KEYCLOAK', payload: {sub:'NO AUTH', token:'NO AUTH'}})
                    })
            }
            else {
                setUser({...user, sub:'NO AUTH', token:'NO AUTH'})
                store.dispatch({type:'KEYCLOAK', payload: {sub:'NO AUTH', token:'NO AUTH'}})
            }
    },[])

    if (!valid || !user) return <div></div>
  return (
    <PageForty>
        {detailsConfirmation &&
            <Confirmation_ 
                question = 'Θέλουμε να σας ενημερώσουμε ότι η Doitforme δεν είναι υπεύθυνη για τα στοιχεία που παρέχονται από τον επισκέπτη ως ελεύθερο κείμενο. Υπάρχει πιθανότητα προσβλητικού περιεχομένου.'
                primaryText = 'Πίσω στην ασφάλεια'
                primaryIcon = 'Xicon'
                onClickPrimary = {() => (rejectRisk())}
                secondaryText = 'Αποδέχομαι τον κίνδυνο'
                secondaryIcon = 'Check'
                onClickSecondary = {() => (acceptRisk())}
            />
        }
        <PageAlwaysVisible>
            <PageTitle>
                <h1>Διανομέας εργασίας της Doitforme</h1>
            </PageTitle>
            <p>Χαίρετε, <br/>ένας επισκέπτης της πλατφόρμας ψάχνει για υπηρεσίες κοντά στην περιοχή σας, ρίξτε μια ματιά!</p>
            <NifflerWrapper>
                <h3>Περιγραφή υπηρεσίας</h3>
                <NifflerInfoRow>
                    <p>Υπηρεσία που αναζητήθηκε:</p>
                    <h4>{valid.job}</h4>
                </NifflerInfoRow>
                <NifflerSvgRow>
                    <IconSvg Icon='Location' />
                    <h4>{valid.city}</h4>
                </NifflerSvgRow>
                <NifflerSvgRow>
                    <IconSvg Icon='Schedule' />
                    <h4>{valid.meeting}</h4>
                </NifflerSvgRow>
                <StartDetails>
                    {!risk ?
                        <StartDetails>
                            <Main_Plain 
                                noPadding
                                buttonText= 'Θέλω να δω λεπτομέρειες για την υπηρεσία'
                                onClick = {() => toggleDetailsConfirmation(!detailsConfirmation)}
                            />
                        </StartDetails>
                    :
                        <StartDetails>
                            <Main_Plain 
                                noPadding
                                buttonText= 'Απόκρυψη λεπτομερειών'
                                onClick = {() => setRisk(false)}
                            />
                            <p>{valid.extraInfo}</p>
                        </StartDetails>
                    }
                </StartDetails>
            </NifflerWrapper>
            <StartActions>
                <Focus_ 
                    buttonText= 'Ενδιαφέρομαι'
                    onClick={() => i_want_this_job()}
                />
                <Grey_Link 
                    buttonText= 'Δεν ενδιαφέρομαι'
                    buttonIconLeft = 'Xicon'
                />
            </StartActions>
        </PageAlwaysVisible>
        <PageDesktopOnly>
            <TempSvg>
                <img src={LandSvg}></img>
            </TempSvg>
        </PageDesktopOnly>
    </PageForty>
  )
}
