import styled from "styled-components";

export const NifflerWrapper = styled.div`
    margin-top: 4rem;
    background: ${p => p.theme.low};
    padding: 3rem 4rem;
    border-radius: var(--smallRadius);

    h3{
        margin-bottom: 1rem;
    }
`

export const NifflerInfoRow = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

export const NifflerSvgRow = styled(NifflerInfoRow)`
    margin-top: 1rem;

    svg{
        width: 2rem;
    }
`