import { PageAlwaysVisible, PageDesktopOnly, PageForty, PageTitle } from 'monica-alexandria'
import styled from 'styled-components'
import React from 'react'

import LandSvg from '../../assets/requestFullfilled.svg'

const TempSvg = styled.div`
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img{
        max-width: 80rem;
    }
`

export default function LATE() {
  return (
    <PageForty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>Ζητούμε συγγνώμη για την αναστάτωση...</h1>
            </PageTitle>
            <p>Λυπούμαστε που σας ενημερώνουμε ότι ένας άλλος επαγγελματίας πάροχος υπηρεσιών έχει ήδη διεκδικήσει τις πληροφορίες του πελάτη.</p>
        </PageAlwaysVisible>
        <PageDesktopOnly>
            <TempSvg>
                <img src={LandSvg}></img>
            </TempSvg>
        </PageDesktopOnly>
    </PageForty>
  )
}
