import { Confirmation_, IconSvg, Main_Plain } from 'monica-alexandria'
import React, { useState } from 'react'
import styled from 'styled-components'
import { NifflerInfoRow, NifflerSvgRow, NifflerWrapper } from '../styles/Layout'

const JobDescriptionDetails = styled.div`
    margin-top: 2rem;
`

export default function Job_Description(props) {
    const [ detailsConfirmation, toggleDetailsConfirmation] = useState(false)
    const [ risk, setRisk] = useState(false)
    const acceptRisk = () => {
        toggleDetailsConfirmation(!detailsConfirmation)
        setRisk(true)
    }

    const rejectRisk = () => {
        toggleDetailsConfirmation(!detailsConfirmation)
        setRisk(false)
    }

  return (
    <NifflerWrapper>
        {detailsConfirmation &&
            <Confirmation_ 
                question = 'Θέλουμε να σας ενημερώσουμε ότι η Doitforme δεν είναι υπεύθυνη για τα στοιχεία που παρέχονται από τον επισκέπτη ως ελεύθερο κείμενο. Υπάρχει πιθανότητα προσβλητικού περιεχομένου.'
                primaryText = 'Πίσω στην ασφάλεια'
                primaryIcon = 'Xicon'
                onClickPrimary = {() => (rejectRisk())}
                secondaryText = 'Αποδέχομαι τον κίνδυνο'
                secondaryIcon = 'Check'
                onClickSecondary = {() => (acceptRisk())}
            />
        }
        <h3>Περιγραφή υπηρεσίας</h3>
        <NifflerInfoRow>
            <p>Υπηρεσία που ζητήθηκε:</p>
            <h4>{props.form.job}</h4>
        </NifflerInfoRow>
        <NifflerSvgRow>
            <IconSvg Icon='Location' />
            <h4>{props.form.city}</h4>
        </NifflerSvgRow>
        <NifflerSvgRow>
            <IconSvg Icon='Schedule' />
            <h4>{props.form.meeting}</h4>
        </NifflerSvgRow>
            {!risk ?
                <JobDescriptionDetails>
                    <Main_Plain 
                        noPadding
                        buttonText= 'Θέλω να δω λεπτομέρειες για την υπηρεσία'
                        onClick = {() => toggleDetailsConfirmation(!detailsConfirmation)}
                    />
                </JobDescriptionDetails>
            :
                <JobDescriptionDetails>
                    <Main_Plain 
                        noPadding
                        buttonText= 'Απόκρυψη λεπτομερειών'
                        onClick = {() => setRisk(false)}
                    />
                    <p>{props.form.extraInfo}</p>
                </JobDescriptionDetails>
            }
    </NifflerWrapper>
  )
}
