import axios from "axios"
import { config } from "../config"

const get_form = (formId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.BACKEND_URL}/microservices/niffler/forms/${formId}`)
            .then(res => resolve(res))
            .catch(() => {
                console.error('GET FORM')
                resolve(false);
            })
    })
}

const get_coupon = (formId, userId, token) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.NIFFLER_BACKEND_URL}`+'/api/userForm?formId='+
                    (formId ? formId: '') +
                    (token ? '&token='+token: '') +
                    (userId ? '&userId='+userId: ''))
            .then(res => resolve(res))
            .catch((err) => {
                console.log(err)
                console.error('GET FORM')
                resolve(false);
            })
    })
}

const get_coupon_user = (formId, userId, token) => {

    return new Promise((resolve, reject) => {

        let data = {};

        if (userId) data.userId = userId;
        if (formId) data.formId = formId;
        if (token) data.token = token;

        axios({url:`${config.NIFFLER_BACKEND_URL}/api/userCoupon`, method: 'post', data: data})
            .then(res => resolve(res))
            .catch(() => {
                console.error('GET FORM')
                resolve(false);
            })
    })
}


export {get_form, get_coupon, get_coupon_user};