import { Focus_, IconSvg, PageAlwaysVisible, PageDesktopOnly, PageForty, PageTitle } from 'monica-alexandria'
import {React, useEffect, useState} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components'
import Job_Description from '../../components/Job_Description'
import { NifflerInfoRow, NifflerSvgRow, NifflerWrapper } from '../../styles/Layout'
import {get_form, get_coupon, get_coupon_user} from '../../services/api';
import querystring, { parse } from 'query-string'
import { useKeycloak } from '@react-keycloak/web'
import store from '../../redux/store'

import LandSvg from '../../assets/afterContact.svg'

const ContactActions = styled.div`
    margin-top: 4rem;
`

const TempSvg = styled.div`
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img{
        max-width: 80rem;
    }
`

export default function CONTACT(props) {

    const parsedurl = querystring.parse(window?.location?.search)

    const formId = parsedurl.formId;
    const userId = store.getState()?.user?.sub;
    const token = store.getState()?.user?.token;
    const [user, setUser] = useState(false)
    let navigate = useNavigate();
    
    const { keycloak } = useKeycloak();

    const [ form, setForm] = useState(false)

    const get_form_details = () => {
        get_coupon(formId, user?.sub, keycloak.token).then((valid) => {
            get_coupon_user(formId, user?.sub, keycloak.token).then((secret) => {

                console.log('data', secret)
                if (secret?.data?.status == 401) navigate(`/el/join?formId=${formId}`)
                else setForm({...valid?.data, ...secret?.data})
            })
        })
    }

    useEffect(()=>{keycloak.loadUserInfo()
        .then(data => {
            
            setUser(data)
        })
        .catch(() => setUser(true))},[])

    useEffect(()=>{
        if (user) get_form_details()
    },[user])

    if (!form) return <div></div>

  return (
    <PageForty>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>Πληροφορίες πελάτη</h1>
            </PageTitle>
            <p>Παρακάτω θα βρείτε όλες τις πληροφορίες που παρείχε ο πελάτης στην πλατφόρμα μας.</p>
            <p>Ελπίζουμε να ολοκληρωθεί η υπηρεσία που ζητήσατε, may the force be with you.</p>
            <NifflerWrapper>
                <h3>Στοιχεία επικοινωνίας</h3>
                <NifflerInfoRow>
                    <p>Όνομα:</p>
                    <h4>{form.firstname}</h4>
                </NifflerInfoRow>
                <NifflerInfoRow>
                    <p>Επώνυμο:</p>
                    <h4>{form.lastname}</h4>
                </NifflerInfoRow>
                <NifflerSvgRow>
                    <IconSvg Icon='Mobile' />
                    <h4>{form.phone}</h4>
                </NifflerSvgRow>
                <ContactActions>
                    {form.phone ? <a href={`tel:${form.phone}`}>
                    <Focus_ 
                        buttonIconLeft = 'Phone'
                        buttonText = 'Καλέστε τώρα'
                    />
                    </a> : null}
                </ContactActions>
            </NifflerWrapper>
            <Job_Description form={form}/>
        </PageAlwaysVisible>
        <PageDesktopOnly>
            <TempSvg>
                <img src={LandSvg}></img>
            </TempSvg>
        </PageDesktopOnly>
    </PageForty>
  )
}
