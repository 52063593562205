import { Card_Cover, PageAlwaysVisible, PageCards, PageSixty, PageTitle } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components'
import querystring from 'query-string'
import { useKeycloak } from '@react-keycloak/web'

const JoinWrapper =styled.div`
    margin-bottom: 4rem;
    
    h3{
        margin-top: 1rem;
    }
`

export default function JOIN() {

    const parsedurl = querystring.parse(window?.location?.search);
    const formId = parsedurl?.formId;
    const { keycloak } = useKeycloak();
    let location = useLocation();
    let url = location.pathname.split('/');
    let lang = url[0];

    console.log(`-----------------------------------${process.env.REACT_APP_PROTOCOL} 
    ${process.env.REACT_APP_HOST}
    ${process.env.REACT_APP_PORT}`)

    const generate_redirect_link = () => {
        return(`${process.env.REACT_APP_NIFFLER_FRONTEND_PROTOCOL}${process.env.REACT_APP_NIFFLER_FRONTEND_HOST}${process.env.REACT_APP_NIFFLER_FRONTEND_PORT}/el/contact/form?formId=${parsedurl.formId}`)
    }

    const login = () => {
        keycloak.login({redirectUri : generate_redirect_link(),
                            locale: lang})
    }

    const register = () => {
        keycloak.login({action:'register', redirectUri : generate_redirect_link(),
                            locale: lang})
    }
    
    return (
        <PageSixty>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>Join the network</h1>
                </PageTitle>
                <JoinWrapper>
                    <p>Μόνο οι χρήστες της Doitforme έχουν πρόσβαση στα στοιχεία επικοινωνίας του πελάτη.</p>
                    <h3>Είστε νέος χρήστης;<br/> Η Doitforme σας δίνει τις πληροφορίες του πρώτου σας πελάτη δωρεάν!</h3>
                </JoinWrapper>
                <PageCards>
                    <Card_Cover 
                        onClick={() => login()}
                        center
                        img="https://genius1071.friktoriaservers.net/mongol/app/defaults/cover/cover_1.jpg"
                        smallTitle="Σύνδεση σε υπάρχον λογαριασμό"
                    />
                    <Card_Cover 
                        onClick={() => register()}
                        center
                        img="https://genius1071.friktoriaservers.net/mongol/app/defaults/cover/cover_1.jpg"
                        smallTitle="Δημιουργία νέου λογαριασμού"
                    />
                </PageCards>
            </PageAlwaysVisible>
        </PageSixty>
    )
    }
