import { Page, Theme, ThemeDark, ThemeLight } from "monica-alexandria";
import React, { useState } from "react";
import { Routes, Route} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CONTACT from "../pages/contact/CONTACT";
import JOIN from "../pages/join/JOIN";
import LATE from "../pages/late/LATE.js";
import PSYCHOLOGISTS from "../pages/psychologists/PSYCHOLOGISTS";
import START from "../pages/start/START";

export default function Niffler() {

    const [theme, setTheme] = useState('light')

  return (
    <ThemeProvider theme={theme === 'dark' ? ThemeDark : ThemeLight}>
        <Theme>
            <Page>
                <Routes>
                    <Route path="/" element={<START/>} />
                    <Route path="/:lang" element={<START/>} />
                    <Route path="/:lang/forms/:form" element={<START/>} />
                    <Route path="/:lang/contact/form" element={<CONTACT/>} />
                    <Route path="/:lang/late" element={<LATE/>} />
                    <Route path="/:lang/join" element={<JOIN/>} />
                    <Route path="/:lang/form" element={<PSYCHOLOGISTS/>} />
                </Routes>
            </Page>
        </Theme>            
    </ThemeProvider>
  );
}