import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Niffler from './router/Router';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './services/keycloak'

let options={onLoad: 'check-sso', checkLoginIframe: false}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactKeycloakProvider initOptions={options} authClient={keycloak} LoadingComponent={<div></div>}>
    <BrowserRouter>
      <Niffler/>
    </BrowserRouter>
  </ReactKeycloakProvider>
);
